<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('deposit_wait_unpaid.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('deposit_wait_unpaid.head_list') }}</v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :label="$t('deposit_wait_unpaid.date_from')" :date="startDate"
                @setValue="startDate = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :label="$t('deposit_wait_unpaid.date_to')" :date="endDate"
                @setValue="endDate = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchDeposit"> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.page" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="statement">
            <template v-slot:[`item.bank`]="{ item }">
              <div class="col-12 pa-0 py-1">
                <div class="w-100 ma-auto" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                  <div v-if="!item.bankCountry">
                    <img v-if="item.bank" alt="bank_img"
                      style="object-fit: contain;height: inherit;width: 100%;"
                      :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                  </div>
                  <div v-else>
                    <img v-if="item.bank" alt="bank_img"
                      style="object-fit: contain;height: inherit;width: 100%;"
                      :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                  </div>
                </div>
              </div>
              <span>{{ item.bank_account }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template> <template v-slot:[`item.bonus`]="{ item }">
              {{ item.bonus | currency }}
            </template> <template v-slot:[`item.total`]="{ item }">
              {{ item.total | currency }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-autocomplete v-if="!item.username && isUpdateCredit(item.date)" v-debounce:500ms="searchUser" class="my-2" v-model="item.member_id"
                :placeholder="$t('deposit_wait_unpaid.select')" dense :items="members" item-text="label"
                :loading="item.loading" :disabled="item.loading" outlined hide-details
                @change="updateCredit(item)"></v-autocomplete>
              <p v-if="item.username" class="ma-0">{{ item.username }}</p>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      alertMessage: false,
      valueMember: '',
      searchData: null,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      loading: false,
      startDate: moment().subtract(3, 'days').format('YY-MM-DD'),
      endDate: moment().format('YY-MM-DD'),
      headers: [
        {
          text: this.$t('deposit_wait_unpaid.header1'),
          value: 'bank',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header2'),
          value: 'bank_name',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header3'),
          value: 'detail',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header4'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header5'),
          value: 'amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header6'),
          value: 'action',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header7'),
          value: 'admin',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('deposit_wait_unpaid.header8'),
          value: 'time_change',
          width: '100px',
          align: 'center',
          sortable: false,
        },

      ],
      statement: [],
      members: [],
      bankKey: this.$store.getters.getKeyBank,
      flag_interval: null,
      dummy: true,
      bankSystem: ''
    }
  },
  async created() {
    this.getWebSettingInfo()
    this.addLogPage()
    this.searchUser()
    this.refreshdata()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'รายการรอฝาก(คงค้าง)',
            url: window.location.href,
            detail: 'ระบบจัดการรายการรอฝาก(คงค้าง)',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchUser(search) {
      this.loading = true
      let rs = await this.$store.dispatch('getMember', {
        page: 1,
        rows: 25,
        type: 'member',
        ...(search ? { search: search } : {}),
      })
      if (rs.count > 0) {
        this.members = []
        rs.data.forEach(element => {
          this.members.push({
            value: element.id,
            label: `${this.bankKey[element.bank] ? this.bankKey[element.bank] : element.bank} ${element.bank_account
              } : ${element.phone} - ${element.name}`,
          })
        })
      }
      this.pagination.page = 1
      this.loading = false
    },
    async searchDeposit() {
      this.dummy = false
      await this.getDepositData(
        1,
        this.pagination.itemsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData
      )
      this.pagination.page = 1
    },
    async getDepositData(page, row, time_from, time_to, search) {
      if (page !== 1 || search) {
        clearInterval(this.flag_interval)
      }
      try {
        this.loading = true
        if (this.$route.name === 'creditwait') {
          this.statement = []
          const params = {
            page: page,
            rows: row,
            ...(!search ? { page: page } : {}),
            ...(!search ? { rows: row } : {}),
            status: 'wait',
            ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
            ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
            ...(search ? { search: search } : {}),
          }
          let rs = await this.$store.dispatch('getDeposit', params)
          this.pagination.totalItems = rs.count

          rs.data.forEach(element => {
            let splitCountry, splitBankCode;
            if (element.accountbank && element.accountbank?.bank_code) {
              const code = element.accountbank.bank_code;
              const checkCountryCode = (code) => {
              const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
              const firstTwoChars = code.substring(0, 2);
                return validCodes.includes(firstTwoChars);
              }

              if (checkCountryCode(code)) {
                splitCountry = code.slice(0, 2);
                splitBankCode = code.slice(2);
              } else {
                splitCountry = this.bankSystem;
                splitBankCode = code;
              }

              element.accountbank.bankCountry = splitCountry.toLowerCase();
              element.accountbank.bank = splitBankCode;
            }

            this.statement.push({
              id: element.id,
              amount: element.amount,
              bonus: element.bonus,
              member_id: 0,
              admin: element.admin ? element.admin.name : null,
              username: element.member ? element.member.phone : null, //phone number
              bank: element.accountbank ? element.accountbank.bank : null,
              bank_account: element.accountbank ? element.accountbank.bank_account : null,
              bank_name: element.accountbank ? element.accountbank.bank_name : null,
              bankCountry: element.accountbank ? element.accountbank.bankCountry : null,
              date: moment(element.created_at).format('YY-MM-DD HH:mm'),
              total: element.amount + element.bonus,
              detail: element.transaction_bank ? element.transaction_bank.message : null,
              time_change: element.time_change_status ? moment(element.time_change_status).format('YY-MM-DD HH:mm:ss') : null,
              loading: false,
            })
          })
          // }
        } else {
          clearInterval(this.flag_interval)
        }
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
      this.loading = false
    },
    async updateCredit(item) {
      item.loading = true
      try {
        let params = {
          id: item.id,
          member_id: item.member_id,
        }
        await this.$store.dispatch('updateDeposit', params)
        await this.getDepositData(this.pagination.page, this.pagination.itemsPerPage)
        this.$swal.fire(this.$t('deposit_wait_unpaid.update_complete'), '', 'success')
      } catch (e) {
        // if (!e?.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e?.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }

        this.$swal.fire({
          icon: 'error',
          title: 'ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง',
        }).then(action => {
          this.searchDeposit()
        })
      }
      // let rs =
      item.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      await this.getDepositData(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },
    refreshdata() {
      this.flag_interval = setInterval(() => {
        this.getDepositData(1, this.pagination.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value)
      }, 180000)
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    isUpdateCredit(dateString) {
      const inputDate = moment(dateString, 'YYYY-MM-DD HH:mmss')
      const currentDate = moment();
      const diffInHours = currentDate.diff(inputDate, 'hours', true);
      return diffInHours <= 1;
    },
  },
}
</script>
